import React from "react";

import classes from "./OmladinskiPogon.module.css";

import Header from "../../components/Header/Header";
import ContentBox from "../../components/UI/ContentBox/ContentBox";
import Footer from "../../components/Footer/Footer";

const OmladinskiPogon = ({ pageTitle = "" }) => {
  document.title = pageTitle;

  return (
    <>
      <Header />
      <ContentBox>
        <div className={classes.flexx}>
          <p className={classes.comingsoon}>Uskoro!</p>
        </div>
      </ContentBox>
      <Footer />
    </>
  );
};

export default OmladinskiPogon;
