const daysArray = [
  "Nedjelja",
  "Ponedjeljak",
  "Utorak",
  "Srijeda",
  "Četvrtak",
  "Petak",
  "Subota",
];
const monthsArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Maj",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];

const formatDateTime = (unixTimestamp, time = false, date = false) => {
  const dateObj = new Date(unixTimestamp * 1000);

  let result = "";
  if (date) {
    const day = daysArray[dateObj.getDay()];
    const month = monthsArray[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    const dateNum = dateObj.getDate();
    result += `${day} | ${month}, ${dateNum} ${year}`;
  }

  if (time) {
    if (result !== "") result += ", ";
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    result += `${hours}:${minutes}`;
  }

  return result;
};

const isToday = (timestamp) => {
  const givenDate = new Date(timestamp * 1000);
  const today = new Date();

  return givenDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
};

const timeFromNow = (timestamp) => {
  const now = new Date();
  const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // Pretvaranje trenutnog vremena u UTC
  const futureDate = new Date(timestamp * 1000);
  const tomorrow = new Date(utcNow);
  tomorrow.setDate(utcNow.getDate() + 1); // Postavljanje na sutrašnji datum
  tomorrow.setHours(0, 0, 0, 0); // Postavljanje na početak dana

  const difference = futureDate - utcNow;

  if (difference < 0) return "Čeka se";
  if (difference < 86400000 && futureDate.getDate() === utcNow.getDate())
    return "Danas";
  if (difference < 172800000 && futureDate.getDate() === tomorrow.getDate())
    return "Sutra";

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const months = Math.floor(days / 30);

  if (months > 0) {
    return "";
  } else if (days > 0) {
    return `${days} dana`;
  }
};

const timestampToData = (dateFormat) => {
  const date = new Date(dateFormat);

  const month = monthsArray[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}, ${day} ${year}`;
};

const isHTMLContentEmpty = (content) => {
  if (content.replace(/<(.|\n)*?>/g, "").trim().length === 0) return true;
  else return false;
};

const firstToUpper = (str) => {
  if (str.length === 0) return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export {
  formatDateTime,
  isToday,
  timeFromNow,
  isHTMLContentEmpty,
  timestampToData,
  firstToUpper,
};
