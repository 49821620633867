import { useNavigate } from "react-router-dom";

const useNavigateWithRefresh = () => {
  const navigate = useNavigate();

  const navigateWithRefresh = (to) => {
    navigate(to);
    window.location.reload(false);
  };

  return navigateWithRefresh;
};

export default useNavigateWithRefresh;
