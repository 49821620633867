import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import classes from "./Sponsors.module.css";

const Sponsors = () => {
  const sponsors = useSelector((state) => state.global.sponsors);

  const navigateHandler = (link) => window.open(link, "_blank");

  return (
    <>
      <div className={classes.goldenContainer}>
        {Array.isArray(sponsors) &&
          sponsors.length > 0 &&
          sponsors.map(
            (sponsor, index) =>
              sponsor.type === 1 && (
                <Link
                  key={index}
                  to={sponsor.link}
                  target="_blank"
                  title={sponsor.name}
                >
                  <img
                    src={`${process.env.REACT_APP_SERVER_URL}/${sponsor.image}`}
                    alt={sponsor.name}
                    title={sponsor.name}
                  />
                </Link>
              )
          )}
      </div>
      <div className={classes.otherContainer}>
        {Array.isArray(sponsors) &&
          sponsors.length > 0 &&
          sponsors.map(
            (sponsor, index) =>
              sponsor.type === 2 && (
                <img
                  key={index}
                  src={`${process.env.REACT_APP_SERVER_URL}/${sponsor.image}`}
                  alt={sponsor.name}
                  title={sponsor.name}
                  onClick={() => navigateHandler(sponsor.link)}
                />
              )
          )}
      </div>
    </>
  );
};

export default Sponsors;
