import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminActions } from "../../store";
import { Link } from "react-router-dom";
import useNavigateWithoutScroll from "../../hooks/useNavigateWithoutScroll";

import classes from "./Header.module.css";
import LazyImage from "../UI/LazyImage/LazyImage";
import logo from "../../assets/NK-TOŠK-200x200.png";
import logoCut from "../../assets/tosk-logo-cut.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosCloseCircle } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { VscSignOut } from "react-icons/vsc";
import { firstToUpper } from "../../utils/utils";

const logoutLabel = "Odjava";

const navigationItems = [
  {
    label: "Početna",
    link: "/",
    subItems: [],
    subItemsLinks: [],
  },
  {
    label: "Novosti",
    link: "",
    subItems: [""], //zanemari jer povlaci iz baze
    subItemsLinks: [],
  },
  {
    label: "Prvi tim",
    link: "",
    subItems: ["Igrači"],
    subItemsLinks: ["/igraci"],
  },
  {
    label: "Omladinski pogon",
    link: "/omladinski-pogon",
    subItems: [],
    subItemsLinks: [],
  },
  {
    label: "O klubu",
    link: "",
    subItems: ["Historija kluba", "Organi kluba"],
    subItemsLinks: ["/historija-kluba", "/organi-kluba"],
  },
  {
    label: "Članarina",
    link: "/clanarina",
    subItems: [],
    subItemsLinks: [],
  },
  {
    label: "A-Panel",
    link: "",
    subItems: [],
    subItemsLinks: [],
  },
  {
    label: logoutLabel,
    link: "",
    subItems: [],
    subItemsLinks: [],
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateWithoutScroll = useNavigateWithoutScroll();

  const isAdmin = useSelector((state) => state.admin.isAdmin);
  const categories = useSelector((state) => state.global.categories);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobileSubmenuOpen, setIsMobileSubmenuOpen] = useState(0);

  useEffect(() => {
    dispatch(adminActions.checkIsAdmin());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isNavOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isNavOpen]);

  const handleResize = () => {
    if (window.innerWidth > 1000) {
      setIsMobile(false);
      setIsNavOpen(false);
    } else {
      setIsMobile(true);
    }
  };

  const handleToggleNav = () => setIsNavOpen(!isNavOpen);

  const submenuHandler = (number, item) => {
    if (number === isMobileSubmenuOpen) setIsMobileSubmenuOpen(0);
    else setIsMobileSubmenuOpen(number);

    if (item.link.length > 0) {
      setIsNavOpen(false);
      navigate(item.link);
    }
    if (item.label === logoutLabel) {
      handleLogout();
      setIsNavOpen(false);
    }
  };

  const handleLogout = () => {
    dispatch(adminActions.logoutAdmin());
  };

  const isLabelForAdmin = (label) => {
    if ((label === logoutLabel || label === "A-Panel") && !isAdmin) return true;
    else return false;
  };

  return (
    <header className={classes.header}>
      <LazyImage
        src={logo}
        alt="NK TOŠK"
        onClick={() => {
          navigateWithoutScroll("/");
        }}
      />

      <nav className={classes.nav}>
        {isMobile ? (
          <>
            <GiHamburgerMenu
              className={classes.menuIcon}
              onClick={handleToggleNav}
            />

            {isNavOpen && (
              <div className={classes.mobileNavContainer}>
                <img className={classes.backgroundLogo} src={logoCut} alt="" />
                <IoIosCloseCircle
                  className={classes.closeIcon}
                  onClick={handleToggleNav}
                />
                <ul className={classes.mobileUl}>
                  {navigationItems.map((item, index) =>
                    isLabelForAdmin(item.label) ? null : (
                      <li
                        key={index}
                        onClick={() => submenuHandler(index, item)}
                      >
                        {item.label === logoutLabel && (
                          <div className={classes.mobileIconContainer}>
                            <VscSignOut
                              className={classes.icon}
                              title={item.label}
                            />
                            {item.label}
                          </div>
                        )}
                        {item.label === "A-Panel" && (
                          <div className={classes.mobileIconContainer}>
                            <IoSettingsOutline
                              className={classes.icon}
                              title={item.label}
                            />
                            {item.label}
                          </div>
                        )}
                        {item.label !== logoutLabel &&
                          item.label !== "A-Panel" &&
                          item.label}
                        {item.subItems.length > 0 &&
                          isMobileSubmenuOpen === index && (
                            <ul className={classes.mobileSubmenu}>
                              {item.label === "Novosti"
                                ? categories.map((cat, catIndex) => (
                                    <li
                                      key={catIndex}
                                      className="removeTextDecoration"
                                      onClick={() => {
                                        setIsNavOpen(false);
                                        navigate(
                                          `/novosti/${categories[catIndex].slug}`
                                        );
                                      }}
                                    >
                                      • {cat.slug && firstToUpper(cat.slug)}
                                    </li>
                                  ))
                                : item.subItems.map((subItem, subIndex) => (
                                    <li
                                      key={subIndex}
                                      onClick={() => {
                                        navigateWithoutScroll(
                                          item.subItemsLinks[subIndex]
                                        );
                                      }}
                                    >
                                      • {subItem}
                                    </li>
                                  ))}
                            </ul>
                          )}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </>
        ) : (
          <ul className={classes.ul}>
            {navigationItems.map((item, index) =>
              isLabelForAdmin(item.label) ? null : (
                <li
                  key={index}
                  className={classes.li}
                  onClick={() => submenuHandler(index, item)}
                >
                  {item.label === logoutLabel && (
                    <VscSignOut
                      className={classes.signOut}
                      title={item.label}
                    />
                  )}
                  {item.label === "A-Panel" && (
                    <IoSettingsOutline
                      className={classes.signOut}
                      title={item.label}
                    />
                  )}
                  {item.label !== logoutLabel &&
                    item.label !== "A-Panel" &&
                    item.label}
                  {item.subItems.length > 0 && (
                    <div className={classes.submenu}>
                      <div className={classes.flex}>
                        <ul>
                          {item.label === "Novosti"
                            ? categories.map((cat, catIndex) => (
                                <Link
                                  key={catIndex}
                                  className="removeTextDecoration"
                                  to={`/novosti/${categories[catIndex].slug}`}
                                >
                                  <li>
                                    • {cat.slug && firstToUpper(cat.slug)}
                                  </li>
                                </Link>
                              ))
                            : item.subItems.map((subItem, subIndex) => (
                                <li
                                  key={subIndex}
                                  onClick={() => {
                                    navigateWithoutScroll(
                                      item.subItemsLinks[subIndex]
                                    );
                                  }}
                                >
                                  • {subItem}
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </li>
              )
            )}
          </ul>
        )}
      </nav>
    </header>
  );
};

export default Header;
