import React from "react";
import { useSelector } from "react-redux";
import useNavigateWithRefresh from "../../hooks/useNavigateWithRefresh";
import { getCategoryNameById } from "../../store";
import classes from "./NewsList.module.css";

const NewsList = ({
  news = [],
  withoutSlug = "",
  skeleton = false,
  skeletonItems = 0,
}) => {
  const navigateWithRefresh = useNavigateWithRefresh();
  const categories = useSelector((state) => state.global.categories);

  return (
    <>
      {!skeleton ? (
        <div className={classes.newsContainer}>
          {news.map(
            (x, index) =>
              // without current news
              x.slug !== withoutSlug && (
                <div
                  className={classes.cardContainer}
                  key={index}
                  onClick={() =>
                    navigateWithRefresh(
                      `/novosti/${getCategoryNameById(
                        categories,
                        x.category,
                        true
                      )}/${x.slug}`
                    )
                  }
                >
                  <img
                    className={classes.thumbnail}
                    src={`${process.env.REACT_APP_SERVER_URL}/${x.thumbnailResized}`}
                    alt={x.title}
                  />
                  <div className={classes.cardTitleContainer}>
                    <p>{getCategoryNameById(categories, x.category)}</p>
                    <h3>{x.title}</h3>
                  </div>
                </div>
              )
          )}
        </div>
      ) : (
        <div className={classes.newsContainer}>
          {Array.from({ length: skeletonItems }).map((_, index) => (
            <div key={index} className={classes.skeletonContainer}>
              <div className={classes.skeletonImage}></div>
              <div className={classes.skeletonTitle}></div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default NewsList;
