import React from "react";

import classes from "./FormWrapper.module.css";
import { IoIosCloseCircle } from "react-icons/io";
import Button from "../Button/Button";

const FormWrapper = ({
  children,
  title,
  onClose,
  onSubmit,
  button = "",
  style,
  errorMessage = "",
}) => {
  return (
    <div className={classes.container}>
      <IoIosCloseCircle className={classes.closeIcon} onClick={onClose} />
      <form className={classes.form} onSubmit={onSubmit} style={style}>
        <h2>{title}</h2>
        {children}
        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
        <Button title={button} type="submit" />
      </form>
    </div>
  );
};

export default FormWrapper;
