import React from "react";

import classes from "./Cover.module.css";

const TitleBanner = ({ title }) => {
  return (
    <div className={classes.titleContainer}>
      <h1>{title}</h1>
    </div>
  );
};

export default TitleBanner;
