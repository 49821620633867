import React from "react";

import classes from "./ClubHistory.module.css";

import Header from "../../components/Header/Header";
import ContentBox from "../../components/UI/ContentBox/ContentBox";
import LazyImage from "../../components/UI/LazyImage/LazyImage";
import Footer from "../../components/Footer/Footer";
import Cover from "../../components/UI/Cover/Cover";

import logo from "../../assets/NK-TOŠK-200x200.png";
import slika1 from "../../assets/historija/utakmica sa ekipom Plamen iz Travnika.jpg";
import slika2 from "../../assets/historija/Ekipa Udarnika. U prvom planu Hamza Ruštić, Rašid Deljkić i Muharem Kotorić.png";
import slika3 from "../../assets/historija/TOŠK- Napredak Modriča 21 – utakmica odigrana 05.05.1957. godine.png";
import slika4 from "../../assets/historija/Igrači Željezničara i TOŠK-a u četvrtfinalu Kupa maršala Tita.png";
import slika5 from "../../assets/historija/Juniori TOŠK-a 1980. godine.png";
import atomci from "../../assets/historija/Atomci.jpg";
import atomciLogo from "../../assets/historija/Atomci Logo.png";
import slika6 from "../../assets/historija/TOŠK - Bakljada pred utakmicu sa Žepčem.jpg";
import slika7 from "../../assets/historija/Šampionska gerneracija.jpg";

const ClubHistory = ({ pageTitle = "" }) => {
  document.title = pageTitle;

  return (
    <>
      <Header />
      <Cover title={"Historija kluba"} />
      <ContentBox>
        <h2>TOŠK - 97 godina fudbala u Tešnju</h2>
        <div>
          <LazyImage
            src={logo}
            alt="NK TOŠK"
            style={{ width: "200px", height: "auto", marginBottom: "40px" }}
            className={classes.logo}
          />
        </div>
        <p>
          Prva fudbalska lopta na prostoru BiH stigla je u Mostar 1903. godine.
          Donijeli su je austrougarski oficiri. Tu su ujedno počeli i prvi
          fudbalski treninzi. Prva fudbalska utakmica odigrana je 16. oktobra
          1903. godine između ekipe austrougarske vojske i radničke sportske
          omladine grada Mostara. Na naše prostore fudbalska lopta je prvo došla
          u Teslić. Donijeli su je radnici iz Austro-ugarske koji su radili u
          Destilaciji drva. Obzirom da je Teslić nastao kao grad nakon okupacije
          od strane Austro-ugarske i uspostavljanja proizvodnih kapaciteta
          Destilacije, tamo su posao našli i Tešnjaci koji su na taj način došli
          u kontakt sa fudbalskom loptom i donijeli je u Tešanj. Grupa
          entuzijasta osnovala je klub 1927. godine, a kao osnivači se spominju:
          Aširaga i Mujaga Mujčić, Ahmet Školjić, Aljo Šehagić i Hakija
          Srkalović. Prvi predsjednik je bio Aširaga Mujčić. Naziv kluba TOŠK
          predstavlja skraćenicu Tešanjski omladinski športski klub.
        </p>
        <p>
          Prvu utakmicu TOŠK je odigrao 8.8.1930. godine protiv Proletera iz
          Teslića i pobijedio sa rezultatom 2:1. Slavilo se cijelu noć, a sutra
          cjelovečernje slavlje je izazvalo zabranu igranja nogometa jer su ga
          žandari smatrali opasnošću za vlast. Međutim, rad je obnovljen upravo
          zahvaljujući uglednim Tešnjacima, osnivačima kluba. Igrači su sami
          sebi kupovali kopačke, pješačili do bližih mjesta da bi odigrali
          utakmice. Utakmice u Tešnju igrane su na jedinoj slobodnoj površini,
          Vašarištu (mjestu gdje se održavao jednom godišnje veliki vašar) koje
          se nalazilo na mjestu današnje OŠ Huso Hodžić. TOŠK je svoje utakmice
          igrao na ovom igralištu sve do 1961. godine. Teren je bio neravan,
          šljunkovit i veoma često oštećen bujicom rijeke koja je često odnosila
          dragocjene lopte. Klub je prve utakmice, nakon tesličkog Proletera,
          igrao sa tadašnjim ekipama iz BiH kao što su „Plamen“ iz Travnika,
          „Đerzelez“ iz Sarajeva, „Desta“ i „Hrabri“ iz Teslića, „Ozren“ iz
          Doboja i drugih, jer još uvijek nisu bila formirana ligaška
          takmičenja.
        </p>
        <p>
          Narodni heroj Nisim Albahari sa učenicima Gimnazije imao udjela u
          prvim TOŠK-ovim aktivnostima. On spominje sljedeće glavne aktere: Aljo
          Šehagić, Pero Marković, Smail Širbegović, Huso Dolamić, Aširaga i
          Mujaga Mujčić, Hakija Srkalović. Kao prvi igrači i treneri TOŠK-a
          spominju se: trener i vođa navale Aljo Šehagić, Mustafa Bešlagić
          (golman), Rašid i Muhamed Đonlagić, Huso Dolamić, Rašid Deljkić
          (golman), Esad Jusufović, Perica Marković, Smail Širbegović, Nisim
          Albahari (golman). Prilikom gostovanja ekipa iz daljih mjesta igrale
          su se dvije utakmice. Tako je 1932.godine, u Tešnju gostovala ekipa
          „Plamen“ iz Travnika koja je u Tešnju odigrala dvije utakmice.
          Tešnjaci su od početka prihvatili klub i pomagali ga. Tešanjske gazde
          uz pomoć bogatih trpeza dovodili su igrače iz Teslića, ali bez novčane
          naknade.
        </p>
        <div>
          <LazyImage
            src={slika1}
            alt="NK TOŠK - utakmica sa ekipom Plamen iz Travnika"
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>
            Fotografija i njena poleđina igrača TOŠK-a sa utakmice sa ekipom
            Plamen iz Travnika
          </p>
        </label>
        <p>
          TOŠK prestaje sa radom početkom Drugog svjetskog rata. Odmah po
          oslobođenju grada pokrenuta je ideja da se TOŠK reaktivira. Obzirom na
          neposredno poratno vrijeme, nastajala su imena klubova koja su
          odražavala tadašnje vrijeme kao što su: Sloboda, Borac, Bratsvo i sl.
          TOŠK-u je ime promjenjeno u Udarnik, a na dresovima se isticala
          zvijezda, petokraka. Počinju se organizirati ligaška takmičenja na
          nivou Dobojske regije, a kasnije i u Sjeveroistočnoj zoni koja je
          obuhvatala tuzlansko i dobojsko područje. Jake ekipe imali su:
          Bosanski Šamac, Odžak, Derventa, Modriča, a TOŠK-ove utakmice sa
          Slogom iz Doboja i Natronom iz Maglaja bile su pune rivalskog naboja.
          Kada je TOŠK igrao kvalifikacionu utakmicu sa Natronom, u Maglaju
          nastala je priča da je u to vrijeme u gradu ostao samo hodža i dežurni
          vatrogasac. Svi njegovi stanovnici su pješice, zaprežnim kolima i
          drugim prevoznim sredstvima otišli u Maglaj.
        </p>
        <div>
          <LazyImage
            src={slika2}
            alt="NK TOŠK - ekipa Udarnika. U prvom planu Hamza Ruštić, Rašid Deljkić i Muharem Kotorić"
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>
            Ekipa Udarnika. U prvom planu Hamza Ruštić, Rašid Deljkić i Muharem
            Kotorić
          </p>
        </label>
        <div>
          <LazyImage
            src={slika3}
            alt="TOŠK- Napredak Modriča 2:1 - utakmica odigrana 05.05.1957. godine."
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>
            TOŠK - Napredak Modriča 2:1 - utakmica odigrana 05.05.1957. godine.
            <br />
            Stoje s lijeva: Smail Smailbegović, Kemal Tahirović, Hamdija
            Denjalić Amidža, Hakija Bašić, Ćamil Muratović Mrki, trener Salih
            Sarić, Tahir Turalić Ždrebe, Edib Abduzaimović Ćoso, Satko Hukić i
            Esad Mašnić Eso.
            <br />
            Čuče: Mehmedalija Smailbegović, Sakib Zejnilović, Đoko Vasiljević,
            Smail Galijašević.
          </p>
        </label>
        <p>
          Od 1949. godine TOŠK vraća svoje prvobitno ime. Pojavljuje se nova
          generacija mladih igrača koji su obilježili više od deset sljedećih
          godina: Sakib Zejnilović, Hakija Bašić, Ćamil Muratović, Muhamed
          Jahić, Muhamed Tahirović, Kemal Tahirović, Esad Mašnić, Hamdija
          Denjalić, Kemal Tahirović, Edib Abduzaimović Ćoso, Smail Medarić,
          Safet Kapić, Esad Mašnić, Satko Hukić, Đoko Vasiljević,Smail
          Galijašević, Alija Škapurević, Aziz Dervišević i dr. A onda 1959.
          godine dolazi do crnog perioda u TOŠK-ovoj historiji. Dolazi do
          prekida značajne utakmice sa Zvijezdom iz Gradačca. Tada su igrači
          TOŠK-a napali sudiju Đuru Vukelića iz Doboja, a onda im se pridružili
          navijači i nastao je haos jer su napadnuti i igrači Zvijezde. Desilo
          se to kod rezultata 1:1, a u Tešnju se poslije govorilo o „dobojskoj
          zavjeri“ obzirom na rivalitet Sloge i TOŠK-a. Klub je drastično
          kažnjen suspenzijom u trajanju od dvije godine, a veći broj igrača
          kažnjen je zabranom igranja od 6 mjeseci do dvije godine. Tešnjaci su
          oduvijek sanjali ravan prostor kako bi se napravio normalan fudbalski
          stadion. Suspenzija je bila prilika da se riješi i ovo pitanje. Seid
          Hodžić i Omer Smailbegović pronalaze sadašnju lokaciju koja se zvala
          Berberovića luke. Počela je gradnja novog stadiona. U međuvremenu
          jedan broj igrača je prešao u Borac iz Jelaha. TOŠK je jednu
          polusezonu kao domaćin igrao na Rastokama u Jelahu. Godine 1961
          otvoren je novi stadion na Lukama utakmicom TOŠK- Garnizon Doboj.
        </p>
        <p>
          Utakmica je završena rezultatom 3:3. Dolazi jedna nova, veoma
          talentovana generacija igrača i 1963.godine počinje uspon TOŠK-a, kada
          su se igrale dvije kvalifikacione utakmice protiv Natrona iz Maglaja.
          Prvu utakmicu u Maglaju TOŠK je dobio sa 3:0 i to je vrijeme kada se
          bukvalno sav Tešanj tog dana preselio u Maglaj. Nakon sedam dana istim
          rezultatom postignuta je i druga pobjeda i TOŠK se plasirao u viši
          rang takmičenja. Slijedećih deset i više godine igrala je sjajna
          generacija u kojoj su bili: Mustafa Dautefendić, Adil Bukvić, Sedžad
          Šeremet, Ilija Gverić, Šemsudin i Hajrudin Sofić, Besim Hotić, Fuad
          Sarić, Muris i Mustafa Jabandžić, Salko i Nedžad-Nećko Hadžiselimović,
          Jozo Katinić, Juso Mešić, Mugdim Kadić, Nesko Huskić, Ismet Isić,
          Nedeljko Popović, Milovan Pezerović, Vlado Kojić, Nedeljko Popović i
          dr. Sredinom 1966. godine ukidaju se sve republičke lige na području
          bivše Jugoslavije.
        </p>
        <p>
          U BiH se formiraju četiri zone (najveći rang takmičenja na nivou
          republika) i to: Sjeveroistočna bosanska u kojoj se takmiči TOŠK,
          Sarajevska, Hercegovačka i Krajiška. Sjeverobosanska istočna liga bila
          je veoma jaka. U njoj su igrali: Sloga iz Doboja, Jedinstvo iz Odžaka,
          Jedinstvo i Lokomotiva iz Brčkog, Borac iz Bosanskog Šamca, Hajduk iz
          Orašja, Zvijezda iz Gradačca, Drina iz Zvornika, Slaven iz Živinica,
          Radnik iz Lipnice, Tekstilac iz Dervente, Budućnost iz Banovića,
          Radnik iz Bijeljine, Proleter iz Dvorova, Željezničar iz Doboja.
        </p>
        <p>
          U ovom periodu TOŠK je ostvario i nekoliko velikih uspjeha. Tako je
          1969. godine osvojio Kup maršala Tita na području Dobojske regije da
          bi u nastavku takmičenja izgubio od tuzlanske Slobode sa 2:0. Dvije
          godine kasnije TOŠK ponovo osvaja Kup Dobojske regije, kada je u
          finalu ponovo pobijedio dobojsku Slogu.
        </p>
        <p>
          Historijsku utakmicu TOŠK je odigrao pred oko 3.000 gledalaca protiv
          Željezničara iz Sarajeva u šesnaestini finala Kupa maršal Tito. Željo
          je tada imao šampionsku ekipu koja je u sezoni 1971/72. postala prvak
          Jugoslavije i bio je veliki favorit. TOŠK je tada bio u sredini tabele
          Sjeveroistočne bosanske lige i bio je pred utakmicu totalni autsajder.
          Međutim TOŠK je zaigrao izvanredno i Adil Bukvić je u 65. minutu
          makazicama dao prvi gol, a u 80. minutu Dautefendić Mustafa drugi.
          Pred kraj utakmice penal za goste, koji je izvodio Janković, odbranio
          je Salko Hadžiselimović. Trener TOŠK-a je bio Ćamil Muratović,
          predsjednik Rizah Sofić, a sekretar Hasan Brkić. U polufinalu, TOŠK je
          igrao u Trebinju sa Leotarom i izgubio sa 2:1.
        </p>
        <div>
          <LazyImage
            src={slika4}
            alt="Igrači Željezničara i TOŠK-a u četvrtfinalu Kupa maršala Tita"
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>
            Igrači Željezničara i TOŠK-a u četvrtfinalu Kupa maršala Tita
            <br />
            Jugoslavija - 27.10.1971. godine
          </p>
        </label>
        <p>
          TOŠK je igrao u bijelim dresovima. S lijeva čuče: Smail Smailbegović,
          Adil Bukvić Hare, Ilija Gverić Ilica, Salko Hadžiselimović, Hajrudin
          Saračević, Šemsudin Sofić Šeme, Dragan Kojović, Juso Mešić, Slobodan
          Kojović, Branislav Jelušić, i Džemaludin Šerbo. Stoje: Zlatko
          Mišković, Fikret Mulalić glavni sudija i Arslanović (sudijska trojka),
          Blagoje Bratić, Besim Hotić, Slobodan Janjuš, Mustafa Dautefendić
          Bijeli, Sedžad Šeremet, Enver Hadžiabdić, Edin Sprečo, Nedžad
          Hadžiselimović Nećko, Vlado Kojić, Nusret Kadrić, Fahrija Hrvat. Pored
          igrača sa fotografije u igru su kod Željezničara ulazili Avdija
          Deraković i Boško Janković.
        </p>
        <p>
          U redovnom prvenstvu, TOŠK je najveći uspjeh ostvario 1972. godine
          kada je osvojio 4. mjesto. sa istim brojem bodova kao i Hajduk iz
          Orašja koji je imao bolju gol razliku. (Koje godine?) Početkom 1974.
          godine stadion koji je imao samo zemljani teren preuređuje se tako da
          dobiva nove dimenzije, drenažu, i novo busenje. U to vrijeme utakmice
          se, zahvaljujući predsjedniku ovog kluba Štefku Tomiću, bez ikakve
          naknade igraju na stadionu Proletera u Tesliću. Nakon toga, novcem
          Samoupravne interesne zajednice za kulturu i fizičku kulturu, Fabrike
          pumpi i hidraulike i Fabrike filtera osnovnih organizacija Pobjede,
          izgrađena je upravna zgrada čime je stadion dobio svoju današnju
          fizionomiju (izuzev betonskih tribina). Na novom stadionu odigrana je
          prva utakmica sa maglajskim Natronom koju je TOŠK dobio sa 1:0.
        </p>
        <p>
          TOŠK je odigrao veliki broj prijateljskih utakmica sa poznatim
          klubovima iz bivše Jugoslavije, a dvije utakmice su imale i
          međunarodni karakter. U Tešnju su igrali Sarajevo, Željezničar (koji
          je bio najčešći gost i koji je često obavljao pripreme u Tešnju),
          Sloboda, Velež, Čelik, Borac iz Banja Luke, Lokomotiva iz Moskve
          (pobijedila TOŠK 8:1), te madžarski Szolnoki kojeg je TOŠK pobijedio
          3:1.
        </p>
        <div>
          <LazyImage
            src={slika5}
            alt="Juniori TOŠK-a 1980. godine"
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>
            Juniori TOŠK-a 1980. godine
            <br />
            Stoje s lijeva: Mustafa Dautefendić trener, Muhamed Agić, Mustafa
            Jabandžić, Aljo Turkeš, Jasmin Subašić, Hamza Braković, Ahmet Brka,
            Mehinović Pihko.
            <br />
            Čuče: Husein Alić, Mevludin Krdžalić Dino, Muris Jabandžić Tigar,
            Drago Zovko, Esad Muharemović Plavi i Ivo Bilonić
          </p>
        </label>
        <p>
          Početkom ratnih dejstava 1992. godine prestaju se igrati utakmice.
          Jedan broj igrača ostaje na prostoru Tešnja, jedan broj odlazi van
          BiH. Međutim prijateljske utakmice se igraju i u vrijeme rata. Iz
          Doboja je izbjegao u Tešanj jedan broj dobrih fudbalera. Igrale su se
          utakmice u vrijeme ratnih dejstava (sa Čelikom). Fudbaleri i fudbalski
          radnici iz Doboja formiraju klub Bosna ekspres. Međutim nije dugo
          igrao već su se igrači uključili u NK TOŠK koji je nakon igranja u
          Prvoj B ligi nakon osvojenog drugog mjesta, 1999 goidne ušao zajedno
          sa Krajinom iz Cazina u Premijer ligu Republike BiH. Premijer liga je
          igrana na prostoru RBiH koji je bio pod kontrolom Armije BiH.
          Predsjednik kluba je bio Amir Galijašević, a direktor Muris Jabandžić.
          Izgrađene su betonske tribine kako bi stadion primio veći broj
          gledalaca. Tada je Tešanj ponovo bio uz TOŠK. U Tešanj dolaze Željo,
          Sarajevo, Sloboda, Čelik, Velež…
        </p>
        <p>
          Šef stručnog štaba je bio Fahrudin Zejnilović, pomoćnici Mustafa
          Jabandžić i Salko Hadžiselimović. U ovoj uspješnoj generaciji su
          igrali:Vahid Mujkić, Admir Omerčić, Admir Bukvić, Hajrudin Deljkić,
          Mirsad Hodžić, Abdulah Osmić, Miralem Pašić, Osman Huseljić, Nermin
          Ibraković, Daglas Numanović, Edhem Hasanbašić, Ismet Hadžiahmetović,
          Anis Demirović, Adnan Hasanović, Almir Bukvić, Vahid Delić, Alen
          Šahbaz, Ervin Skula, Hasan Rakman, Ernad Braković, Azem Braković,
          Samir Sejdić, Sedžad Ahmić, Zaim Hozić,… Atomci su organizovana grupa
          navijača koja je osnovana 1998. godine i 20 godina su velika podrška
          TOŠK-u.
        </p>
        <h2 style={{ marginBottom: "0px" }}>ATOMCI</h2>
        <div>
          <LazyImage
            src={atomciLogo}
            alt="Atomci Logo"
            style={{ width: "300px", height: "auto" }}
            className={classes.logo}
          />
        </div>
        <div>
          <LazyImage
            src={atomci}
            alt="Atomci"
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>Atomci</p>
        </label>
        <p>
          TOŠK je imao i teških i sjajnih trenutaka u svojoj historiji. Uvijek
          su bili problemi sa novčanim sredstvima i sa infrastrukturom. Rad se
          nije mogao odvijati samo sredstvima dobivenim iz Samoupravne zajednice
          ili budžeta. Uvijek je trebalo namicati dodatna sredstva. Nekada je
          Ahmet Školjić hranio dva siromašna fudbalera koji su bili ratna
          siročad, a poslije su Pobjeda i druge firme zapošljavale fudbalere
          kako bi ostvarili uslove za trening i igru. Fabrike su pomagale i
          organizovanjem prevoza na utakmice van Tešnja. Mnogi privrednici,
          privredne organizacije i vršioci raznih funkcija su bili uključeni u
          upravu, kako bi stvarali uslove za rad. Viši rang je tražio više
          sredstava. Prije rata 1992-1995 su to bile državne fabrike, a poslije
          rata privatne firme na čelu sa vlasnicima i direktorima koji su
          iskreno voljeli TOŠK: Ahmet Školjić, Salih Sarić, Izudin Alićehajić,
          Smail Handžić Bata, Muhamed Saračević, Madi (Edin Jabandžić), Belif
          (Adem Šišić), Hifa (Izudin Ahmetlić), Mann und Hummel (Mahmut
          Galijašević) Koteks (Haris Sejdić), Agfa print Edin Mujčić-Peci) i dr.
        </p>
        <p>
          Nakon organizovane akcije, pojedinci i kolektivi su obezbijedili
          novčana sredstva za ugradnju stolica na stadionu. Tešanj je znao
          okretati leđa TOŠK-u, ali ga i strasno prihvatiti kako to čini danas,
          kada su Luke u prosjeku najposjećeniji stadion u Prvoj ligi
          Federacije.
        </p>
        <p>
          Mnogo pojedinaca je dalo svoj volonterski doprinos dugovječnom životu
          TOŠK-a koji od svog osnivanja doživljava simbiozu sa gradom. Jedan
          broj igrača TOŠK-a nastavio je karijeru u većim klubovima BiH i u
          inostanstvu kao što su: Mustafa Dautefendić i Jasmin Dervišević (Čelik
          Zenica), Nihad Alić (Sloboda Tuzla), Salko Hadžiselimović (Kozara iz
          Bosanske Gradiške). Muris Jabandžić je dvije godine bio prvi golman
          Omladinske reprezentacije BiH, nakon čega ga je Dragoslav Šekularac
          odveo na probu u Crvenu Zvezdu, Značajne karijere su ostvarili u
          inostranim klubovima: Avdija Vršajević (reprezentativac BiH), Ajdin
          Mahmutović, Admir Ljevaković, Jasmin Dervišević. Admir Omerčić i Edin
          Šaranović su bili članovi Amaterske reprezentacije BiH. Treneri su
          dugo vremena bili domaći igrači TOŠK-a da bi zadnjih godina dolazili
          kvalitetni treneri iz drugih mjesta. Nemoguće ih je sve nabrojati, ali
          značajni treneri TOŠK-a su bili: Seid Hodžić, Ćamil Muratović, Sedžad
          Šeremet, Sakib Zejnilović, Asim Saračević, Fahrudin Zejnilović,
          Sulejman Spahić Haler i drugi.
        </p>
        <p>
          Na raznim pozicijama u upravi (predsjednik, sekretar, direktor) bili
          su Rizo Sofić, Eso Mehinović, Hasan Brkić Krco, Izudin Ibrahimkadić,
          Amir Galijašević, Haris Sejdić, Ramiz Brkić, Muris Jabandžić Tigar,
          Edin Šaranović i drugi. Utakmice su pratili i tešanjski ljekari i
          intervenisali kod povreda: dr. Nikola Ljahov, dr Džemal Artuković, dr
          Emir Čabrić i dr. Uvijek su tu bili i vatreni navijači, često i
          članovi uprave, pomagaći kluba kao što su: Smail Kahrimanović, Edib
          Abduzaimović, Ahmet Školjić, Enver Korajlić, Vahid Eminagić, Hasan
          Ahmetagić Kalaja, Hasan Pobrić, Aljo Dautefendić, Omer Sejdinović,
          Osman Gazić, Osman Dusinović Hero, Edhem i Ekrem Hundur, Ishak Šehagić
          Hako i mnogi drugi. Nakon reorganizacije takmičenja uključivanjem
          klubova sa cijele teritorije BiH, TOŠK je igrao u Kantonalnoj ligi
          Ze-Do kantona da bi u sezoni 2016/2017 ušao u Prvu ligu Federacije.
        </p>
        <div>
          <LazyImage
            src={slika6}
            alt="NK TOŠK - Bakljada pred utakmicu sa Žepčem, proslava ulaska u Prvu ligu Fedracije BiH"
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>
            Bakljada pred utakmicu sa Žepčem, proslava ulaska u Prvu ligu
            Fedracije BiH
            <br />
            (Sezona 2016/17)
          </p>
        </label>
        <div>
          <LazyImage
            src={slika7}
            alt="NK TOŠK - Šampionska generacija 2016/17"
            style={{ width: "70%", height: "auto" }}
            className={classes.image}
          />
        </div>
        <label>
          <p>
            Šampionska generacija 2016/2017
            <br />
            Prvi red s lijeva: Haris Halilović, Amer Rakić, Aldin Bahtić, Samir
            Zeljković, Džemal Hasanbašić, Igor Remetić glavni trener, Adis
            Hankić kapiten, Semin Skulić, Armin Mrguda, Osmo Nuhanović i Amel
            Maglić
            <br />
            Drugi red: Vahidin Dizdarević, Nedim Mujkanović, Eldin Suljić, Hamid
            Navrboc, Muamer Kadušić, Demir Bedak, Uzeir Sinanović, Ahmed
            Husibegović, Armin Bijedić, Amel Bašić, Nedim Dolamić pomoćni
            trener, Harun Strika
          </p>
        </label>
        <div className={classes.creditsContainer}>
          <p>Tekst omogućio: Hesein Galijašević</p>
          <p>Urednik: Nedim Džanić</p>
          <br />
          <p>
            (Korišteni izvori: Tešanjski almanah, Hasan Brkić "TOŠK od općinske
            do Premijer lige" Cko 2000, Glas komuna, arhiva i fotografije
            autora, fotografije iz arhive Hasana Brkića Hasan Brkića)
          </p>
        </div>
      </ContentBox>
      <Footer />
    </>
  );
};

export default ClubHistory;
