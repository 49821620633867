import React from "react";
import { Link } from "react-router-dom";
import useNavigateWithoutScroll from "../../hooks/useNavigateWithoutScroll";

import classes from "./Footer.module.css";
import LazyImage from "../UI/LazyImage/LazyImage";
import logo from "../../assets/NK-TOŠK-200x200.png";

import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { IoMdMail, IoIosFootball } from "react-icons/io";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const navigateWithoutScroll = useNavigateWithoutScroll();

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.logoContactContainer}>
            <LazyImage
              src={logo}
              alt="NK TOŠK"
              onClick={() => {
                navigateWithoutScroll("/");
              }}
            />

            <div className={classes.contactList}>
              <p>NK TOŠK Tešanj</p>
              <p>Patriotske lige 1, Tešanj, Federacija Bosne i Hercegovine</p>
              <p>Tešanj, 74260</p>
              <p>Stadion "Luke" Tešanj</p>
              <p>Tel: 032 941-642</p>
              <p>E-mail: {process.env.REACT_APP_CONTACT_MAIL}</p>
            </div>
          </div>
          <div className={classes.linkListContainer}>
            <ul className={classes.linkList}>
              <li
                onClick={() => {
                  navigateWithoutScroll("/historija-kluba");
                }}
              >
                O klubu
              </li>
              <li>Kontakt</li>
              <li
                onClick={() => {
                  navigateWithoutScroll("/novosti/novosti");
                }}
              >
                Novosti
              </li>
              <li
                onClick={() => {
                  navigateWithoutScroll("/clanarina");
                }}
              >
                Članarina
              </li>
            </ul>
          </div>
          <div className={classes.sociaMedialList}>
            <Link
              to={process.env.REACT_APP_CONTACT_FB}
              target="_blank"
              title="Facebook"
            >
              <FaFacebookSquare className={classes.fb} />
            </Link>

            <Link
              to={process.env.REACT_APP_CONTACT_IG}
              target="_blank"
              title="Instagram"
            >
              <FaInstagram className={classes.ig} />
            </Link>

            <Link
              to={process.env.REACT_APP_CONTACT_YT}
              target="_blank"
              title="YouTube"
            >
              <FaYoutube className={classes.yt} />
            </Link>

            <Link to="#" title="E-mail">
              <IoMdMail className={classes.mail} />
            </Link>

            <Link
              to={process.env.REACT_APP_CONTACT_TRANSFERMARKT}
              target="_blank"
              title="Transfer Markt"
            >
              <IoIosFootball className={classes.transfermarkt} />
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.copyrightContainer}>
        <p>© {year} NK TOŠK Tešanj. All rights reserved.</p>
        <p>
          Development and design by{" "}
          <Link
            to={"https://nedimdzanic.com"}
            target="_blank"
            className={classes.link}
          >
            www.nedimdzanic.com
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
