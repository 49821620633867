import tosklogo from "../assets/NK-TOŠK-200x200.png";

const teamNameCorrection = [
  { name: "Sloboda Tuzla", change: "FK Sloboda" },
  {
    name: "TOŠK Tešanj",
    change: "NK TOŠK",
    logo: tosklogo,
  },
  { name: "Stupčanica Olovo", change: "NK Stupčanica" },
  { name: "Goražde", change: "FK Goražde" },
  {
    name: "Vis Kosova",
    change: "BFK Simm Bau",
    logo: "https://static.flashscore.com/res/image/data/6quLpmZg-WpD1boJs.png",
  },
  {
    name: "Budućnost Banovići",
    change: "FK Budućnost",
    stadium: "Gradski Stadion Banovići",
  },
  {
    name: "Tomislav",
    change: "HNK Tomislav",
    logo: "https://static.flashscore.com/res/image/data/Ecl1PhYA-YmMTiBym.png",
  },
  {
    name: "Gradina",
    change: "OFK Gradina",
    stadium: "Gradski Stadion Srebrenik",
  },
  {
    name: "Gornji Rahić",
    change: "FK G. Rahić",
    logo: "https://static.flashscore.com/res/image/data/W4ZVW2zB-xEIRMJee.png",
  },
  { name: "Zvijezda Gradačac", change: "NK Zvijezda" },
  { name: "Čelik", change: "NK Čelik" },
  { name: "Bratstvo Gračanica", change: "NK Bratstvo" },
  {
    name: "Radnik Hadžići",
    change: "FK Radnik H.",
    stadium: "Gradski Stadion Hadžići",
  },
  { name: "Jedinstvo Bihać", change: "NK Jedinstvo" },
  { name: "Rudar Kakanj", change: "FK Rudar K." },
  { name: "Mladost Doboj Kakanj", change: "FK Mladost DK" },
];

const correctTeam = (name, logo, stadium) => {
  const correctedTeam = teamNameCorrection.find((team) => team.name === name);
  if (correctedTeam && correctedTeam.logo) {
    logo = correctedTeam.logo;
  }
  if (correctedTeam && correctedTeam.stadium) {
    stadium = correctedTeam.stadium;
  }
  return {
    name: correctedTeam ? correctedTeam.change : name,
    logo,
    stadium,
  };
};

export { teamNameCorrection, correctTeam };
