import React from "react";

import PageError from "../components/UI/PageError/PageError";

const Page404 = () => {
  document.title = "NK TOŠK Tešanj - Error 404";

  return <PageError />;
};

export default Page404;
