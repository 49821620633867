import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../store/index";

import classes from "./AdminLogin.module.css";

import FormWrapper from "../../../components/UI/FormWrapper/FormWrapper";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);

    e.target.value.trim().length <= 0
      ? setUsernameError(true)
      : setUsernameError(false);

    errorMessage.length > 0 && setErrorMessage("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    e.target.value.trim().length <= 0
      ? setPasswordError(true)
      : setPasswordError(false);

    errorMessage.length > 0 && setErrorMessage("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (username.trim().length <= 0) setUsernameError(true);
    else if (password.trim().length <= 0) setPasswordError(true);
    else {
      setUsernameError(false);
      setPasswordError(false);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_API_URL}/adminlogin/login`,
          {
            username: username,
            password: password,
          }
        );

        if (response.status === 200) {
          dispatch(adminActions.setAdmin(response.data.token));
          navigate("/");
        } else {
          console.log("ERROR:", response.data);
        }
      } catch (error) {
        if (error.response) {
          console.error(
            "Došlo je do greške prilikom slanja zahtjeva na server:",
            error.response.data
          );
          error.response.data.error
            ? setErrorMessage(error.response.data.error)
            : setErrorMessage(
                "Došlo je do greške prilikom slanja zahtjeva na server!"
              );
        } else if (error.request) {
          console.error("Nije dobiven odgovor od servera:", error.request);
        } else {
          console.error(
            "Došlo je do greške pri postavljanju zahtjeva:",
            error.message
          );
        }
      }
    }
  };

  return (
    <FormWrapper
      title="Admin Login"
      button="Login"
      onClose={() => {
        navigate("/");
      }}
      onSubmit={handleFormSubmit}
      errorMessage={errorMessage}
    >
      <div className={classes.formGroup}>
        <label htmlFor="username" className={classes.label}>
          Username
        </label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={handleUsernameChange}
          className={
            usernameError ? `${classes.input} ${classes.error}` : classes.input
          }
        />
      </div>
      <div className={classes.formGroup}>
        <label htmlFor="password" className={classes.label}>
          Password
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={handlePasswordChange}
          className={
            passwordError ? `${classes.input} ${classes.error}` : classes.input
          }
        />
      </div>
    </FormWrapper>
  );
};

export default AdminLogin;
