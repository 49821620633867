import React from "react";

import classes from "./MatchDetailsCard.module.css";
import { formatDateTime, timeFromNow } from "../../utils/utils";

const myTeam = process.env.REACT_APP_MY_TEAM_NAME;

const MatchDetailsCard = ({
  round,
  league,
  dateTimestamp,
  status,
  logo1,
  logo2,
  team1,
  team2,
  goals1,
  goals2,
  winner1,
  winner2,
  stadium,
}) => {
  const isMyTeamWinner = () => {
    let myTeamHomeAway = null,
      winner = null;
    if (team1 === myTeam) myTeamHomeAway = 1;
    if (team2 === myTeam) myTeamHomeAway = 2;
    if (myTeamHomeAway !== null && status === "FT") {
      if (winner1 === null && winner2 === null) winner = 0;
      else if (myTeamHomeAway === 1 && winner1 === true) winner = true;
      else if (myTeamHomeAway === 2 && winner2 === true) winner = true;
      else winner = false;
    }
    return winner;
  };

  const setCardColor = () => {
    let color,
      winner = isMyTeamWinner();
    if (winner === true) color = "#098a10";
    else if (winner === false) color = "#ad0d0d";
    else if (winner === 0) color = "#525151";

    return color;
  };

  const backgroundColor = setCardColor();

  const getStatus = (status, timestamp) => {
    if (status !== "FT") return timeFromNow(timestamp);
    else return status;
  };

  return (
    <div className={classes.matchesContainer} style={{ backgroundColor }}>
      <div className={classes.nextContainer}>
        <p className={classes.league}>
          {round}. kolo - {league}
        </p>
        <p className={classes.date}>
          {formatDateTime(dateTimestamp, false, true)}
        </p>
        <div className={classes.flex}>
          <div className={classes.team}>
            <img src={logo1} alt={team1} />
            <p>{team1}</p>
          </div>
          <div className={classes.resultContainer}>
            <p className={classes.status}>{getStatus(status, dateTimestamp)}</p>

            {status === "FT" && (
              <p className={classes.result}>{`${goals1}:${goals2}`}</p>
            )}
            {status === "NS" && (
              <p className={classes.time}>
                {formatDateTime(dateTimestamp, true, false)}
              </p>
            )}
          </div>

          <div className={classes.team}>
            <img src={logo2} alt={team2} />
            <p>{team2}</p>
          </div>
        </div>
        <p className={classes.stadium}>{stadium}</p>
      </div>
    </div>
  );
};

export default MatchDetailsCard;
