import React, { useState } from "react";

import classes from "./Image.module.css";

const Image = ({ src = "", className = "", alt = "" }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      className={loaded ? `${className} ${classes.loaded}` : className}
      onLoad={() => setLoaded(true)}
    />
  );
};

export default Image;
