import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import classes from "./News.module.css";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PageError from "../UI/PageError/PageError";
import NewsList from "./NewsList";
import Image from "../UI/Image/Image";

import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaShare } from "react-icons/fa";
import { timestampToData } from "../../utils/utils";

const News = ({ category, slug }) => {
  const [news, setNews] = useState([]);

  const [latestNews, setLatestNews] = useState([]);
  const [latestNewsLoading, setLatestNewsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/apanel/news/${slug}`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          //check is valid category from link
          if (response.data[0]["category_slug"] === category)
            setNews(response.data);
          else setNews(undefined);
        } else {
          console.log("Error! No data for this news!");
          setNews(undefined);
        }
      })
      .catch((error) => {
        console.error(error);
        setNews(undefined);
      });
  }, [slug, category]);

  // get latest news
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/apanel/news`, {
        params: { limit: 4 },
      })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setLatestNews(response.data);
        } else {
          console.log("Error! No data for news!");
        }
        setLatestNewsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLatestNewsLoading(false);
      });
  }, [slug]);

  return (
    <>
      {news === undefined && <PageError />}

      {news !== undefined && Array.isArray(news) && news.length > 0 && (
        <>
          <Helmet>
            <title>{news[0].title}</title>
          </Helmet>

          <div className={classes.container}>
            <Header />
            <div className={classes.imgContainer}>
              <Image
                src={`${process.env.REACT_APP_SERVER_URL}/${news[0].thumbnail}`}
                alt={news[0].title}
                className={classes.image}
              />
              <div className={classes.titleContainer}>
                <h1>{news[0].title}</h1>
                <div className={classes.dateContainer}>
                  <Link
                    className={"removeTextDecoration"}
                    to={`/novosti/${category}`}
                  >
                    <p className={classes.category}>{category}</p>
                  </Link>

                  <p className={classes.date}>
                    {timestampToData(news[0].created)}
                  </p>
                </div>
                <div className={classes.shareContainer}>
                  <FaShare className={classes.shareIcon} />

                  <FacebookShareButton
                    url={window.location.href}
                    quote={news[0].title}
                    hashtag={"#nktosk"}
                    description={news[0].summary}
                    className={classes.share}
                  >
                    <FaFacebook className={classes.icon} />
                  </FacebookShareButton>

                  <TwitterShareButton
                    title={news[0].title}
                    url={window.location.href}
                    hashtags={["#nktosk"]}
                    className={classes.share}
                  >
                    <FaXTwitter className={classes.icon} />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
            <div className={classes.textContainer}>
              <div className={classes.contentContainer}>
                <div className={classes.contentContainer2}>
                  <h2 className={classes.summary}>{news[0].summary}</h2>
                  <div
                    className={classes.content}
                    dangerouslySetInnerHTML={{ __html: news[0].content }}
                  />
                </div>
              </div>
              {latestNewsLoading ? (
                <div className={classes.latestNewsContainer}>
                  <NewsList
                    news={latestNews}
                    skeleton={true}
                    skeletonItems={4}
                  />
                </div>
              ) : (
                <div className={classes.latestNewsContainer}>
                  <NewsList news={latestNews} withoutSlug={slug} />
                </div>
              )}
            </div>
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default News;
