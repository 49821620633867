import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./Players.module.css";

import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import { CircleFlag } from "react-circle-flags";
import undefinedPlayer from "../../assets/players/undefined.png";

const Players = ({ list = [], skeleton = 0 }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPrevVisible, setIsPrevVisible] = useState(false);
  const [isNextVisible, setIsNextVisible] = useState(true); // By default, show next button
  const playerContainerRef = useRef(null);

  const initialImageStates = list.map(() => ({
    src: undefinedPlayer,
    error: false,
  }));

  const [imageStates, setImageStates] = useState(initialImageStates);

  let skeletonArray;
  if (skeleton > 0) skeletonArray = Array(skeleton).fill();

  const handleImageError = (index) => {
    const newImageStates = imageStates.map((state, i) => {
      // Postavi stanje slike za svakog igrača koji ima grešku pri učitavanju slike
      if (i === index) {
        return { src: undefinedPlayer, error: true };
      } else {
        return state;
      }
    });
    setImageStates(newImageStates);
  };

  const handleNext = () => {
    const container = playerContainerRef.current;
    const containerWidth = container.clientWidth;
    const scrollLeft = container.scrollLeft;
    const scrollWidth = container.scrollWidth;

    const visibleWidth = scrollLeft + containerWidth;
    const lastPlayerIndex = list.length - 1;

    let lastPlayer = null,
      lastPlayerWidth = 0;

    // Find the last player in the list
    const lastPlayerElement =
      playerContainerRef.current.children[lastPlayerIndex];
    const lastPlayerElementWidth = lastPlayerElement.clientWidth;

    // Check if the last player is fully visible
    if (
      lastPlayerElement.offsetLeft + lastPlayerElementWidth <= visibleWidth &&
      lastPlayerElement.offsetLeft + lastPlayerElementWidth <= scrollWidth
    ) {
      setIsNextVisible(false);
    }

    let nextPlayer = null,
      nextPlayerWidth = 0;

    // Find the next player that is not fully visible or not visible at all
    for (let i = currentIndex + 1; i < list.length; i++) {
      const playerElement = playerContainerRef.current.children[i];
      const playerWidth = playerElement.clientWidth;

      if (playerElement.offsetLeft + playerWidth > visibleWidth) {
        nextPlayer = playerElement;
        nextPlayerWidth = playerWidth;
        break;
      }
    }

    if (nextPlayer) {
      if (nextPlayer.offsetLeft + nextPlayerWidth > scrollWidth) {
        playerContainerRef.current.scrollTo({
          left: scrollWidth - containerWidth,
          behavior: "smooth",
        });
      } else {
        playerContainerRef.current.scrollTo({
          left: nextPlayer.offsetLeft - (containerWidth - nextPlayerWidth),
          behavior: "smooth",
        });
      }
    }

    setCurrentIndex((prevIndex) =>
      prevIndex === lastPlayerIndex ? 0 : prevIndex + 1
    );

    // Check if nextPlayerIndex is at the end
    if (currentIndex === lastPlayerIndex) setIsNextVisible(false);

    // Always show prev navigation button after clicking next
    setIsPrevVisible(true);
  };

  const handlePrev = () => {
    const container = playerContainerRef.current;
    const containerWidth = container.clientWidth;
    const scrollLeft = container.scrollLeft;

    const prevPlayerIndex = currentIndex - 1;

    // Check if the first player is fully visible
    const firstPlayer = playerContainerRef.current.children[0];
    if (firstPlayer.offsetLeft >= scrollLeft) {
      setIsPrevVisible(false);
    }

    if (prevPlayerIndex >= 0) {
      const prevPlayer = playerContainerRef.current.children[prevPlayerIndex];
      const playerWidth = prevPlayer.clientWidth;

      if (prevPlayer.offsetLeft < scrollLeft) {
        playerContainerRef.current.scrollTo({
          left: prevPlayer.offsetLeft,
          behavior: "smooth",
        });
      } else {
        playerContainerRef.current.scrollTo({
          left: prevPlayer.offsetLeft - (containerWidth - playerWidth), // scroll to the beginning of the previous player
          behavior: "smooth",
        });
      }
    } else {
      playerContainerRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? list.length - 1 : prevIndex - 1
    );

    // Check if prevPlayerIndex is at the beginning
    if (prevPlayerIndex <= 0) setIsPrevVisible(false);

    // Always show next navigation button after clicking prev
    setIsNextVisible(true);
  };

  return (
    <div className={classes.navigationContainer}>
      {skeleton > 0 ? (
        <div className={classes.playerContainerSkeleton}>
          {skeletonArray.map((_, index) => (
            <div className={classes.playerSkeleton} key={index}></div>
          ))}
        </div>
      ) : (
        <>
          {isPrevVisible && (
            <FaCircleChevronLeft
              className={`${classes.navButton} ${classes.prevButton}`}
              onClick={handlePrev}
            />
          )}
          {isNextVisible && (
            <FaCircleChevronRight
              className={`${classes.navButton} ${classes.nextButton}`}
              onClick={handleNext}
            />
          )}

          <div ref={playerContainerRef} className={classes.playerContainer}>
            {list.map((player, index) => (
              <div
                className={classes.playerCard}
                key={index}
                onClick={() => {
                  navigate(`/igraci/${player.slug}`);
                }}
              >
                <div className={classes.nameContainer}>
                  <h3 className={classes.surname}>{player.surname}</h3>
                  <h4 className={classes.name}>{player.name}</h4>
                </div>

                <CircleFlag
                  countryCode={player.country}
                  className={classes.countryFlag}
                  height="45px"
                />

                <img
                  src={
                    imageStates[index] && imageStates[index].error
                      ? undefinedPlayer
                      : player.image
                  }
                  onError={() => handleImageError(index)}
                  alt={`${player.name} ${player.surname}`}
                  className={`${classes.player} ${
                    index === currentIndex ? classes.current : ""
                  }`}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Players;
