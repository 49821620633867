import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classes from "./AddNews.module.css";

import { MdLibraryAdd } from "react-icons/md";
import { isHTMLContentEmpty } from "../../../utils/utils";
import FormWrapper from "../../../components/UI/FormWrapper/FormWrapper";

const defaultCategory = "Odaberite kategoriju";

const AddNews = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([{ name: defaultCategory }]);
  const imageUploadInput = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [category, setCategory] = useState(defaultCategory);
  const [content, setContent] = useState("");

  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [contentError, setContentError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/news/categories`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setCategories(response.data);
        } else {
          console.log("Error! No data for categories!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const thumbnailChangeHandler = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    setImageError(false);
  };

  const titleChangeHandler = (e) => {
    setTitle(e.target.value);

    e.target.value.trim().length <= 0
      ? setTitleError(true)
      : setTitleError(false);
  };

  const summaryChangeHandler = (e) => {
    setSummary(e.target.value);

    e.target.value.trim().length <= 0
      ? setSummaryError(true)
      : setSummaryError(false);
  };

  const categoryChangeHandler = (e) => {
    setCategory(e.target.value);

    e.target.value === defaultCategory
      ? setCategoryError(true)
      : setCategoryError(false);
  };

  const contentChangeHandler = (content) => {
    setContent(content);

    isHTMLContentEmpty(content) === true
      ? setContentError(true)
      : setContentError(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!selectedImage) setImageError(true);
    //else if (title.trim().length <= 0) setTitleError(true);
    //else if (summary.trim().length <= 0) setSummaryError(true);
    //else if (category === defaultCategory) setCategoryError(true);
    //else if (isHTMLContentEmpty(content) === true) setContentError(true);
    else {
      setImageError(false);
      setTitleError(false);
      setSummaryError(false);
      setCategoryError(false);
      setContentError(false);

      //thumbnail
      const formData = new FormData();
      formData.append("image", selectedImage);

      const selectedCategory = categories.find((cat) => cat.name === category);
      const categoryId = selectedCategory ? selectedCategory.id : null;

      try {
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/images?folderName=news`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (imageResponse.status === 201) {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_API_URL}/apanel/add-news`,
            {
              title,
              summary,
              category: categoryId,
              content,
              thumbnail: imageResponse.data.thumbnail,
            }
          );

          if (response.status === 200) {
            navigate("/");
          } else {
            console.log("ERROR:", response.data);
          }
        }
      } catch (error) {
        if (error.response) {
          console.error(
            "Došlo je do greške prilikom slanja zahtjeva na server:",
            error.response.data
          );
          error.response.data.error
            ? setErrorMessage(error.response.data.error)
            : setErrorMessage(
                "Došlo je do greške prilikom slanja zahtjeva na server!"
              );
        } else if (error.request) {
          console.error("Nije dobiven odgovor od servera:", error.request);
        } else {
          console.error(
            "Došlo je do greške pri postavljanju zahtjeva:",
            error.message
          );
        }
      }
    }
  };

  const toolbarOptions = [
    [{ header: [false, 2] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"],
  ];

  return (
    <FormWrapper
      title="Dodaj Novost"
      style={{ width: "80%" }}
      button="Dodaj"
      onClose={() => {
        navigate("/");
      }}
      onSubmit={submitHandler}
      errorMessage={errorMessage}
    >
      <div className={classes.formGroup}>
        <label htmlFor="thumbnail" className={classes.label}>
          Slika
        </label>
        <div
          className={
            imageError
              ? `${classes.thumbnailContainer} ${classes.error}`
              : classes.thumbnailContainer
          }
        >
          {selectedImage && (
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              className={classes.thumbnail}
            />
          )}
          <MdLibraryAdd
            className={classes.upload}
            onClick={() => imageUploadInput.current.click()}
          />
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            onChange={thumbnailChangeHandler}
            ref={imageUploadInput}
            style={{ display: "none" }}
          />
        </div>
      </div>

      <div className={classes.formGroup}>
        <label htmlFor="title" className={classes.label}>
          Naslov
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={titleChangeHandler}
          className={
            titleError ? `${classes.input} ${classes.error}` : classes.input
          }
        />
      </div>

      <div className={classes.formGroup}>
        <label htmlFor="summary" className={classes.label}>
          Sažetak
        </label>
        <input
          type="text"
          id="summary"
          value={summary}
          onChange={summaryChangeHandler}
          className={
            summaryError ? `${classes.input} ${classes.error}` : classes.input
          }
        />
      </div>

      <div className={classes.formGroup}>
        <label htmlFor="category" className={classes.label}>
          Kategorija
        </label>
        <select
          name="category"
          id="category"
          value={category}
          onChange={categoryChangeHandler}
          className={
            categoryError ? `${classes.input} ${classes.error}` : classes.input
          }
        >
          <option value={defaultCategory}>{defaultCategory}</option>
          {categories.map((cat, catIndex) => (
            <option key={catIndex} value={cat.name}>
              {cat.name}
            </option>
          ))}
        </select>
      </div>

      <div className={classes.formGroup}>
        <label htmlFor="content" className={classes.label}>
          Sadržaj
        </label>
        <ReactQuill
          value={content}
          onChange={contentChangeHandler}
          className={contentError && classes.content}
          modules={{
            toolbar: toolbarOptions,
          }}
        />
      </div>
    </FormWrapper>
  );
};

export default AddNews;
