import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategorySlugById } from "../../store";

import classes from "./Slideshow.module.css";
import Button from "../UI/Button/Button";

import cover from "../../assets/stadion.jpg";

const slideLimit = 3,
  slideDelay = 8000,
  slideImageDelay = 1000;

const Slideshow = () => {
  const [news, setNews] = useState([]);
  const [categorySlug, setCategorySlug] = useState("");
  const [newsLoading, setNewsLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);

  const categories = useSelector((state) => state.global.categories);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/apanel/news`, {
        params: {
          limit: slideLimit,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setNews(response.data);
          setNewsLoading(false);
        } else {
          console.log("Error! No data for news!");
          setNewsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setNewsLoading(false);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (news.length > 1) {
        if (!resetTimer) {
          setLoaded(false);
          setTimeout(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % news.length);
          }, slideImageDelay);
        } else {
          setResetTimer(false);
        }
      }
    }, slideDelay);
    return () => clearInterval(interval);
  }, [resetTimer, news.length]);

  //getCategorySlugById
  useEffect(() => {
    if (news.length > 0) {
      const slug = getCategorySlugById(categories, news[currentSlide].category);
      setCategorySlug(slug);
    }
  }, [news, categories, currentSlide]);

  const handleSlideChange = (index) => {
    setLoaded(false);
    setTimeout(() => {
      setCurrentSlide(index);
      setResetTimer(true);
    }, slideImageDelay);
  };

  return (
    <>
      {!newsLoading && Array.isArray(news) && news.length > 0 && (
        <div className={classes.container}>
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/${news[currentSlide].thumbnail}`}
            className={loaded ? classes.loaded : ""}
            onLoad={() => setLoaded(true)}
            alt=""
          />
          {news[currentSlide].thumbnail}
          <Link
            className={classes.title}
            to={`/novosti/${categorySlug}/${news[currentSlide].slug}`}
          >
            <h1 className={classes.h1}>{news[currentSlide].title}</h1>
            <Button title="Opširnije" className={classes.button} />
          </Link>
          <div className={classes.navCircles}>
            {news.map((slide, index) => (
              <div
                key={slide.title}
                className={
                  index === currentSlide ? classes.active : classes.inactive
                }
                onClick={() => handleSlideChange(index)}
              ></div>
            ))}
          </div>
        </div>
      )}
      {!newsLoading && news.length < 1 && (
        <div className={classes.container}>
          <img
            src={cover}
            alt=""
            className={loaded ? classes.loaded : ""}
            onLoad={() => setLoaded(true)}
          />
        </div>
      )}
      {newsLoading && (
        <div className={classes.container}>
          <div className={`${classes.title} ${classes.titleSkleton}`}>
            <div className={classes.skeleton}></div>
            <div className={classes.skeleton}></div>
            <div className={classes.buttonSkeleton}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Slideshow;
