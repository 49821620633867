import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import classes from "./Membership.module.css";

import Header from "../../components/Header/Header";
import Cover from "../../components/UI/Cover/Cover";
import Footer from "../../components/Footer/Footer";
import { FaAngleDoubleRight } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import payWeb from "../../assets/payment/PayWeb.png";
import visa from "../../assets/payment/visa.png";
import mastercard from "../../assets/payment/mastercard.png";
import maestro from "../../assets/payment/maestro.png";
import verifiedByVisa from "../../assets/payment/verified_by_visa.png";
import mastercardSecurecode from "../../assets/payment/mastercard_securecode.png";

const questions = [
  {
    question: "Koje su prednosti članarine?",
    answer: "Plaćanjem članarine pomažete radu kluba.",
  },
  {
    question: "Koje je cijena članarine?",
    answer: "Cijena članarine je 20 KM.",
  },
  {
    question: "Da li printate člansku kartu i šaljete na adresu?",
    answer:
      "Da, člansku kartu šaljemo na Vašu adresu ukoliko to označite, a rok isporuke je mjesec dana od dana učlanjivanja.",
  },
  {
    question: "Do kada traje članska karta?",
    answer: "Trenutno sve aktivne članskske karte važe do 01.01.2025. godine.",
  },
  {
    question: "Da li je online plaćanje članarine sigurno?",
    answer:
      "Online plaćanje članarine na našem sajtu je sigurno. Popunjavanje kartičnih podataka i samo plaćanje vršite preko treće strane (Monri), tako da mi nemamo uvid u Vaše podatke o kartici.",
  },
];

const Membership = ({ pageTitle = "" }) => {
  document.title = pageTitle;

  const navigate = useNavigate();
  const [openedAnswer, setOpenedAnswer] = useState(null);

  const toggleAnswer = (index) =>
    setOpenedAnswer((prev) => (prev === index ? null : index));

  return (
    <>
      <Header />
      <Cover title={"Članarina"} />
      <div className={classes.container}>
        <h2>Želite postati član NK TOŠK-a?</h2>
        <button
          className={classes.button}
          onClick={() => {
            navigate("/clanarina/uclani-se");
          }}
        >
          <p>Učlani se</p>
          <FaAngleDoubleRight className={classes.icon} />
        </button>
        <h3 className={classes.h3}>Često postavljena pitanja</h3>
        <ul className={classes.questionsContainer}>
          {questions.map((question, index) => (
            <li key={index} className={classes.question}>
              <div className={classes.flex} onClick={() => toggleAnswer(index)}>
                <p className={classes.question}>
                  {question.question}
                  <span>
                    <IoIosArrowDown
                      className={`${classes.arrow} ${
                        openedAnswer === index ? classes.open : ""
                      }`}
                    />
                  </span>
                </p>
              </div>
              {openedAnswer === index && (
                <p className={`${classes.answer} ${classes.opened}`}>
                  {question.answer}
                </p>
              )}
            </li>
          ))}
        </ul>
        <div className={classes.methodesContainer}>
          <img src={payWeb} alt="" />
        </div>
        <div className={classes.cards}>
          <Link
            className={classes.cardIcon}
            to="https://cis.visa.com"
            target="_blank"
          >
            <img src={visa} alt="" />
          </Link>

          <Link
            className={classes.cardIcon}
            to="https://www.mastercard.ba"
            target="_blank"
          >
            <img src={mastercard} alt="" />
          </Link>

          <Link
            className={classes.cardIcon}
            to="https://www.maestrocard.com"
            target="_blank"
          >
            <img src={maestro} alt="" />
          </Link>

          <Link
            className={classes.cardIcon}
            to="https://www.visa.co.uk/products/visa-secure.html"
            target="_blank"
          >
            <img src={verifiedByVisa} alt="" />
          </Link>

          <Link
            className={classes.cardIcon}
            to="https://www.mastercard.us/en-us.html"
            target="_blank"
          >
            <img src={mastercardSecurecode} alt="" />
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Membership;
