import React from "react";

import classes from "./PageError.module.css";

const PageError = ({
  error = "404",
  errorMessage = "Stranica nije pronađena!",
}) => {
  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>{error}</h1>
      <p className={classes.message}>{errorMessage}</p>
    </div>
  );
};

export default PageError;
