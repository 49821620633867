import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkCategoryBySlug } from "../store";

import NewsCategory from "../components/NewsCategory/NewsCategory";
import NewsComponent from "../components/News/News";
import PageError from "../components/UI/PageError/PageError";

const News = () => {
  const { category, slug } = useParams();
  const categories = useSelector((state) => state.global.categories);
  const [categoryExists, setCategoryExists] = useState(null);

  // check category
  useEffect(() => {
    if (categories.length > 0) {
      const exists = checkCategoryBySlug(categories, category);
      setCategoryExists(exists);
    }
  }, [categories, category]);

  return (
    <>
      {categoryExists === false && <PageError />}
      {categoryExists === true && slug === undefined && (
        <NewsCategory category={category} />
      )}
      {categoryExists === true && slug !== undefined && (
        <NewsComponent category={category} slug={slug} />
      )}
      {}
    </>
  );
};

export default News;
