import React, { useState, useEffect } from "react";
import axios from "axios";

import classes from "./Players.module.css";

import Header from "../../../components/Header/Header";
import Cover from "../../../components/UI/Cover/Cover";
import PlayersComponent from "../../../components/Players/Players";
import Footer from "../../../components/Footer/Footer";

const Players = ({ pageTitle = "" }) => {
  document.title = pageTitle;

  const [gk, setGk] = useState([]);
  const [df, setDf] = useState([]);
  const [md, setMd] = useState([]);
  const [st, setSt] = useState([]);
  const [playerLoading, setPlayerLoading] = useState(true);

  const filterPlayersByPosition = (response, position) => {
    return response.data
      .filter((item) => item.position === position)
      .map((item) => ({
        name: item.name,
        surname: item.surname,
        slug: item.slug,
        country: item.country,
        image: `${process.env.REACT_APP_SERVER_URL}/${item.image}`,
      }));
  };

  //get players
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/players`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          // Filter players by position
          const gkPlayers = filterPlayersByPosition(response, "gk");
          const dfPlayers = filterPlayersByPosition(response, "df");
          const mdPlayers = filterPlayersByPosition(response, "md");
          const stPlayers = filterPlayersByPosition(response, "st");

          setGk(gkPlayers);
          setDf(dfPlayers);
          setMd(mdPlayers);
          setSt(stPlayers);
        } else {
          console.log("Error! No data for players!");
        }
        setPlayerLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setPlayerLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      <Cover title="Igrači" />
      <div className={classes.container}>
        <h3 className={classes.postionTitle}>Golman</h3>
        {!playerLoading ? (
          <PlayersComponent list={gk} />
        ) : (
          <PlayersComponent skeleton={3} />
        )}

        <h3 className={classes.postionTitle}>Odbrana</h3>
        {!playerLoading ? (
          <PlayersComponent list={df} />
        ) : (
          <PlayersComponent skeleton={6} />
        )}

        <h3 className={classes.postionTitle}>Veza</h3>
        {!playerLoading ? (
          <PlayersComponent list={md} />
        ) : (
          <PlayersComponent skeleton={6} />
        )}

        <h3 className={classes.postionTitle}>Napad</h3>
        {!playerLoading ? (
          <PlayersComponent list={st} />
        ) : (
          <PlayersComponent skeleton={6} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Players;
