import React, { useState, useEffect } from "react";
import axios from "axios";

import classes from "./NewsCategory.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import NewsList from "../News/NewsList";
import { firstToUpper } from "../../utils/utils";
import Cover from "../UI/Cover/Cover";

const NewsCategory = ({ category }) => {
  const categoryName = firstToUpper(category);
  document.title = `Kategorija - ${categoryName}`;

  const limit = 16;
  const [news, setNews] = useState(undefined);

  // get news by category
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/apanel/news`, {
        params: { limit, category },
      })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setNews(response.data);
        } else {
          console.log(`No news date for the ${category} category!`);
          setNews([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setNews([]);
      });
  }, [category]);

  return (
    <>
      <Header />
      <Cover title={categoryName} />

      {news === undefined && (
        <div className={classes.newsContainer}>
          <NewsList news={news} skeleton={true} skeletonItems={4} />
        </div>
      )}

      {news !== undefined && Array.isArray(news) && news.length > 0 && (
        <div className={classes.newsContainer}>
          <NewsList news={news} withoutSlug="" />
        </div>
      )}

      {news !== undefined && Array.isArray(news) && news.length < 1 && (
        <div className={`${classes.newsContainer} ${classes.errorContainer}`}>
          <p className={classes.errorMessage}>
            U ovoj kategoriji još nema objava!
          </p>
        </div>
      )}
      <Footer />
    </>
  );
};

export default NewsCategory;
