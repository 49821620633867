import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import classes from "./Home.module.css";
import { correctTeam } from "../utils/teamUtils";

import Header from "../components/Header/Header";
import Slideshow from "../components/Slideshow/Slideshow";
import MatchDetailsCard from "../components/MatchDetailsCard/MatchDetailsCard";
import Sponsors from "../components/Sponsors/Sponsors";
import Footer from "../components/Footer/Footer";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";

const myTeam = process.env.REACT_APP_MY_TEAM_NAME;

const Home = () => {
  document.title = "NK TOŠK Tešanj";

  const [standings, setStandings] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [standingsLoading, setStendingsLoading] = useState(true);
  const [fixturesLoading, setFixturesLoading] = useState(true);
  const [nextMatch, setNextMatch] = useState(0);

  let sliderRef = useRef(null);
  const next = () => sliderRef.slickNext();
  const previous = () => sliderRef.slickPrev();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/statistic/standings`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setStandings(JSON.parse(response.data[0]));
        } else {
          console.log("Error! No data for standings!");
        }
        setStendingsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setStendingsLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/statistic/fixtures`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setFixtures(JSON.parse(response.data[0]));

          const fixturesData = JSON.parse(response.data[0]);
          const next = findNextMatch(fixturesData);
          setNextMatch(next);
        } else {
          console.log("Error! No data for fixtures!");
        }
        setFixturesLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setFixturesLoading(false);
      });
  }, []);

  const findNextMatch = (fixtures) => {
    const currentDate = new Date().getTime();
    const nextMI = fixtures.findIndex((item) => {
      const matchDate = new Date(item.fixture.timestamp * 1000);
      return matchDate > currentDate;
    });
    return nextMI;
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 6000,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: nextMatch,
    swipeToSlide: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1086,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <Slideshow />
      <section className={classes.container}>
        {standingsLoading ? (
          <div className={classes.skeletonTable}></div>
        ) : Array.isArray(standings) && standings.length > 0 ? (
          <table className={classes.table}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th title="Ukupno utakmica">U</th>
                <th title="Pobjede">P</th>
                <th title="Neriješene">N</th>
                <th title="Izgubljene">I</th>
                <th title="Golovi">G</th>
                <th title="Gol-razlika">GR</th>
                <th title="Bodovi">B</th>
              </tr>
            </thead>
            <tbody>
              {standings[0].map((item, index) => {
                const { name, logo } = correctTeam(
                  item.team && item.team.name,
                  item.team && item.team.logo
                );
                return (
                  <tr key={index}>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.rank}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {logo && (
                        <img src={logo} alt="Logo" className={classes.logo} />
                      )}
                      {name}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.all && item.all.played}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.all && item.all.win}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.all && item.all.draw}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.all && item.all.lose}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.all &&
                        `${item.all.goals.for}:${item.all.goals.against}`}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.all && item.all.goals.for - item.all.goals.against}
                    </td>
                    <td className={name === myTeam ? classes.myTeam : ""}>
                      {item.points && item.points}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={classes.error}>Nema dostupnih podataka za tabelu!</p>
        )}

        {fixturesLoading ? (
          <div className={classes.skeletonMatches}>
            <div className={classes.skeletonMatch}></div>
            <div className={classes.skeletonMatch}></div>
          </div>
        ) : (
          <div className={classes.matchesContainer}>
            {Array.isArray(fixtures) && fixtures.length > 0 && (
              <FaCircleChevronLeft
                className={classes.slideArrowLeft}
                onClick={() => {
                  previous();
                }}
              />
            )}
            {Array.isArray(fixtures) && fixtures.length > 0 && (
              <FaCircleChevronRight
                className={classes.slideArrowRight}
                onClick={() => {
                  next();
                }}
              />
            )}
            {Array.isArray(fixtures) && fixtures.length > 0 ? (
              <Slider
                ref={(slider) => {
                  sliderRef = slider;
                }}
                {...settings}
                className={classes.sliderMatches}
              >
                {fixtures.map((item, index) => {
                  const {
                    name: name1,
                    logo: logo1,
                    stadium,
                  } = correctTeam(
                    item.teams.away && item.teams.home.name,
                    item.teams.away && item.teams.home.logo,
                    item.fixture.venue && item.fixture.venue.name
                  );
                  const { name: name2, logo: logo2 } = correctTeam(
                    item.teams.away && item.teams.away.name,
                    item.teams.away && item.teams.away.logo
                  );

                  return (
                    <div className={classes.matchSliderContainer} key={index}>
                      <MatchDetailsCard
                        key={index}
                        round={index + 1}
                        league={
                          item.league.name === "1st League - FBiH"
                            ? "Prva liga FBiH"
                            : item.league.name
                        }
                        dateTimestamp={item.fixture.timestamp}
                        team1={name1}
                        logo1={logo1}
                        team2={name2}
                        logo2={logo2}
                        status={item.fixture.status.short}
                        goals1={item.goals.home}
                        goals2={item.goals.away}
                        winner1={item.teams.home.winner}
                        winner2={item.teams.away.winner}
                        stadium={stadium}
                      />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <p className={classes.error}>
                Nema dostupnih podataka za utakmice!
              </p>
            )}
          </div>
        )}
      </section>
      <Sponsors />
      <Footer />
    </>
  );
};

export default Home;
