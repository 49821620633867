import React, { useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { globalActions } from "./store";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import AdminLogin from "./pages/Admin/Login/AdminLogin";
import AddNews from "./pages/Admin/News/AddNews";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Membership from "./pages/Membership/Membership";
import MemberJoin from "./pages/Membership/MemberJoin";
import ClubHistory from "./pages/Club/ClubHistory";
import ClubStaff from "./pages/Club/ClubStaff";
import OmladinskiPogon from "./pages/OmladinskiPogon/OmladinskiPogon";
import Players from "./pages/FirstTeam/Players/Players";
import Player from "./pages/FirstTeam/Player/Player";

const App = () => {
  const dispatch = useDispatch();

  //get categories
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/news/categories`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          dispatch(globalActions.setCategories(response.data));
        } else {
          console.log("Error! No data for categories!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch]);

  //get sponsors
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/sponsors`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          dispatch(globalActions.setSponsors(response.data));
        } else {
          console.log("Error! No data for sponsors!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/apanel-add-news" element={<AddNews />} />
        <Route path="/novosti/:category/:slug?" element={<News />} />
        <Route
          path="/historija-kluba"
          element={<ClubHistory pageTitle="Historija Kluba - NK TOŠK" />}
        />
        <Route
          path="/organi-kluba"
          element={<ClubStaff pageTitle="Organi Kluba - NK TOŠK" />}
        />
        <Route
          path="/clanarina"
          element={<Membership pageTitle="Članarina - NK TOŠK" />}
        />
        <Route
          path="/clanarina/uclani-se"
          element={<MemberJoin />}
          pageTitle="Članarina - NK TOŠK"
        />
        <Route
          path="/kontakt"
          element={<Contact />}
          pageTitle="Kontakt - NK TOŠK"
        />
        <Route
          path="/omladinski-pogon"
          element={<OmladinskiPogon pageTitle="Omladinski pogon - NK TOŠK" />}
        />
        <Route
          path="/igraci"
          element={<Players pageTitle="Prvi tim - NK TOŠK" />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
