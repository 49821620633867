import React from "react";

import classes from "./ContentBox.module.css";

const ContentBox = ({ children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.contentContainer2}>{children}</div>
      </div>
    </div>
  );
};

export default ContentBox;
