import { createSlice, configureStore } from "@reduxjs/toolkit";

const adminiInitialState = {
  isAdmin: localStorage.getItem("token") || null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState: adminiInitialState,
  reducers: {
    setAdmin(state, action) {
      state.isAdmin = action.payload;
      localStorage.setItem("token", action.payload);
    },
    logoutAdmin(state) {
      state.isAdmin = null;
      localStorage.removeItem("token");
    },
    checkIsAdmin(state) {
      state.isAdmin = localStorage.getItem("token") || null;
    },
  },
});

const globalInitialState = {
  categories: [],
  sponsors: [],
};

const globalSlice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setSponsors(state, action) {
      state.sponsors = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    admin: adminSlice.reducer,
    global: globalSlice.reducer,
  },
});

export const getCategoryNameById = (
  categories,
  categoryId,
  lowercase = false
) => {
  const matchingCategory = categories.find((cat) => cat.id === categoryId);
  if (lowercase)
    return matchingCategory ? matchingCategory.slug.toLowerCase() : "";
  else return matchingCategory ? matchingCategory.slug : "";
};

export const getCategorySlugById = (categories, categoryId) => {
  const matchingCategory = categories.find((cat) => cat.id === categoryId);
  return matchingCategory ? matchingCategory.slug : "";
};

export const checkCategoryBySlug = (categories, categorySlug) => {
  const matchingCategory = categories.find((cat) => cat.slug === categorySlug);
  return matchingCategory ? true : false;
};

export const adminActions = adminSlice.actions;
export const adminReducer = adminSlice.reducer;

export const globalActions = globalSlice.actions;
export const globalReducer = globalSlice.reducer;

export default store;
