import React from "react";

import classes from "./ClubStaff.module.css";

import Header from "../../components/Header/Header";
import Cover from "../../components/UI/Cover/Cover";
import ContentBox from "../../components/UI/ContentBox/ContentBox";
import Footer from "../../components/Footer/Footer";

const ClubStaff = ({ pageTitle = "" }) => {
  document.title = pageTitle;

  return (
    <>
      <Header />
      <Cover title={"Organi Kluba"} />
      <ContentBox>
        <p className={classes.title}>Direktor</p>
        <p>Edin Ogrić</p>

        <p className={classes.title}>Upravni odbor</p>
        <p>
          Edin Mujčić - Predsjednik
          <br />
          Kenan Ahmetlić - Član
          <br />
          Mahmut Galijašević - Član
        </p>

        <p className={classes.title}>Skupština</p>
        <p>
          Amir Dujsić - Predsjednik
          <br />
          Aidin Hadžismailović - Potpredsjednik
          <br />
          Haris Jašić - Potpredsjednik
          <br />
        </p>

        <p className={classes.title}>Nadzorni odbor</p>
        <p>
          Muris Jabandžić - Predsjednik
          <br />
          Halid Galijašević - Član
          <br />
          Amir Galijašević - Član
          <br />
          Edin Ogrić - Disciplinska komisija
          <br />
          Hamdo Spahić - Disciplinska komisija
          <br />
          Muamer Omahić - Disciplinska komisija
          <br />
        </p>
      </ContentBox>
      <Footer />
    </>
  );
};

export default ClubStaff;
