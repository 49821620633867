import { useNavigate, useLocation } from "react-router-dom";

const useNavigateWithoutScroll = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithoutScroll = (path) => {
    const newPath = path.startsWith("/") ? path : `/${path}`; // Ensure path starts with '/'
    if (location.pathname === newPath) {
      smoothScrollToTop(); // Scroll to top smoothly if already on the same page
    } else {
      navigate(path);
      window.scrollTo(0, 0); // Reset scroll position on navigate
    }
  };

  const smoothScrollToTop = () => {
    const scrollStep = -window.scrollY / (500 / 15); // 500ms duration for smooth scroll
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

  return navigateWithoutScroll;
};

export default useNavigateWithoutScroll;
